import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren} from '@angular/core';
import {SceneComponent} from "../scene/SceneComponent";
import {Node} from "../nodes/Node";
import {Context} from "../context.service";
import {Field} from "../domain/Field";
import {PatchRequest} from "../api/PatchRequest";
import {APIService} from "../api.service";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {ConfiguratorService} from "../configurator.service";
import {VisualizerService} from "../visualizer.service";
import {GridForm, GridSection} from "../api/SceneGraphResponse";
import {GridstackComponent} from "gridstack/dist/angular";
import {GridStack, GridStackWidget} from "gridstack";
import {ModelNode} from "../nodes/ModelNode";
import {InputNode} from "../nodes/InputNode";
import {ListType} from "../types/ListType";
import {ListWidget} from "../widget/widget.list";


@Component({
	selector: 'app-form-grid',
	templateUrl: './form-grid.component.html',
	styleUrls: ['./form-grid.component.scss']
})
export class FormGridComponent implements OnInit, AfterViewInit, SceneComponent {
	@Input() node: Node | null = null;
	@Input() form: GridForm | null | undefined = null;
	@Input() isChild: boolean = false;
	@Output() created = new EventEmitter<FormGridComponent>();

	public gridRefs: { [model: string]: GridStack[] } = {};
	public finished: boolean = false;
	public valid: boolean = false;

	public visualizers: SafeHtml[] = [];
	public visualizerPosition: string = 'top';

	// noinspection DuplicatedCode
	@ViewChildren('grids')
	set grids(components: QueryList<GridstackComponent>) {
		if (components.length === 0) {
			return;
		}

		console.log('[+] Populating grid refs (%s)', components.length);
		this.gridRefs = {};
		for (const component of components) {
			const model = component.el.getAttribute('data-model');
			const section = component.el.getAttribute('data-section');
			if (!model || section === null || !component.grid)
				continue;
			if (!(model in this.gridRefs))
				this.gridRefs[model] = [];
			this.gridRefs[model][parseInt(section)] = component.grid;
		}
	}

	constructor(
		private configurator: ConfiguratorService,
		private context: Context,
		private api: APIService,
		private visualizer: VisualizerService,
		private sanitizer: DomSanitizer)
	{
		// Called when field changes as a result of interaction
		this.context.onValidate().subscribe((_field: Field) => {
			this.validate();
		});

		// Called when state changes as a result of a PATCH
		this.context.onChange().subscribe(() => {
			this.update();

			this.api.sendMessage({
				type: 'konfoo',
				cmd: 'change',
				params: {
					session: this.context.getSessionId(),
					state: this.context.getState(),
				},
			});
		});
	}

	ngOnInit(): void {
		if (this.node) {
			if (this.node instanceof ModelNode) {
				const model = (<ModelNode>this.node).getModel();
				this.visualizerPosition = model.getMetadataValue('visualizerPosition', 'top');
				const visualizers = model.getMetadata().get('visualizers');
				if (visualizers) {
					this.initializeVisualizers(visualizers);
				}
			}

			this.created.emit(this);
		}
	}

	ngAfterViewInit() {
		// Deferred to next tick so that Angular can update the view in peace.
		setTimeout(() => {
			this.update();
		}, 0);
	}

	setNode(node: Node): void {
		this.node = node;
		if (this.node instanceof ModelNode) {
			(<ModelNode>this.node).setComponent(this);
		}
	}

	setGridForm(form: GridForm) {
		this.form = form;
	}

	update() {
		this.validate();
	}

	async save() {
		const patches = this.serialize();
		console.log('form-grid saving:', JSON.stringify(patches, null, 4));
		await this.context.changed(patches);
		// TODO: execute ui events
	}

	async finish() {
		this.finished = false;
		// await this.save(); // This is deprecated and no longer needed.

		if (!this.validate()) {
			console.warn('[!] State did not validate entirely');
			return;
		}

		// Finished UI state only when in standalone mode
		if (this.configurator.isStandalone()) {
			this.finished = true;
		}

		this.api.sendMessage({
			type: 'konfoo',
			cmd: 'finish',
			params: {
				session: this.context.getSessionId(),
			},
		});
		console.log('[+] Grid: finish');
	}

	validate(): boolean {
		this.valid = false;
		if (!this.form)
			return false;

		for (let section of this.form.sections) {
			for (let widget of section.grid) {
				if (!this.getNode(widget))
					continue;

				let node = <InputNode>this.getNode(widget);
				if (!node.getInputType().required)
					continue;
				if (!node.hasValue())
					return false;

				// TODO: per-widget validation
				// if (!node.getWidget().isValid())
				// 	return false;
			}
		}

		this.valid = true;
		return this.valid;
	}

	serialize(): PatchRequest[] {
		if (!this.form)
			return [];

		const changed: { [key: string]: PatchRequest } = {};
		const patches = [];

		for (let section of this.form.sections) {
			for (let widget of section.grid) {
				if (!this.getNode(widget))
					continue;

				let node = <InputNode>this.getNode(widget);
				if (node.getField().getType().isReadOnly()) {
					continue;
				}

				const model = node.getModel();
				const modelId = model.getId();
				if (!modelId) {
					throw new Error(`Model ID is null for type '${model.getName()}'`);
				}

				if (!(modelId in changed)) {
					changed[modelId] = {
						id: modelId,
						model: model.getName(),
						patch: {},
					};
				}

				changed[modelId].patch[node.getFieldName()] = node.serialize().value;

				if (node.getInputType() instanceof ListType) {
					const widget = node.getWidget() as ListWidget;
					const component = widget.getCurrentComponent();
					if (component) {
						patches.push(...component.serialize());
					}
				}

				// TODO: handle SelectType
			}
		}

		patches.push(...Object.values(changed));
		return patches;
	}

	getRootModelName(): string | undefined {
		return this.node?.getModel().getName();
	}

	getWidgetId(index: number, w: GridStackWidget): string {
		return index + '';
	}

	getNode(gridItem: any) {
		if (!this.node)
			return null;
		if (gridItem.model !== this.node.getModel().getName()) {
			console.warn(`GridItem ${gridItem.model}.${gridItem.field} tried to access ${this.node}`);
			return null;
		}
		return this.node.findSubnode('field', gridItem.field);
	}

	onSectionClick(section: GridSection) {
		section.__ui_open = !section.__ui_open;
		this.onResize();
	}

	onResize(): void {
		setTimeout(() => {
			for (const model in this.gridRefs) {
				for (const grid of this.gridRefs[model]) {
					for (let i = 0; i < grid.el.children.length; ++i) {
						const child = grid.el.children[i];
						const gridStackNode = (<any>child).gridstackNode;
						if (gridStackNode.type === 'list') {
							grid.resizeToContent(gridStackNode.el);
						}
					}
				}
			}
		}, 250);
	}

	// noinspection DuplicatedCode
	private initializeVisualizers(visualizers: any) {
		for (let key in visualizers) {
			console.log('[+] loading visualizer: %s', key);
			const name = key;
			const url = typeof(visualizers[key]) === 'string' ? visualizers[key] : visualizers[key].url;

			// Create params
			const params = typeof(visualizers[key]) === 'string' ? {} : visualizers[key];
			delete params.url;

			// Override params set by system
			params.session = this.context.getSessionId();
			params.api = this.api.getFullUrl();

			const script = document.createElement('script');
			script.setAttribute('type', 'module');
			script.setAttribute('src', url);
			script.onload = () => {
				console.log('[+] component loaded: %s', name);
				this.visualizers.push(this.visualizer.createVisualizerHtml(name, params));

				// NOTE: this is a fallback for the request/response API
				//       to ensure that the component gets some sort of state before
				//       any user interaction even if it does not request one specifically
				setTimeout(() => {
					this.api.sendMessage({
						type: 'konfoo',
						cmd: 'change',
						params: {
							session: this.context.getSessionId(),
							state: this.context.getState(),
						},
					});
				}, 100);
			};
			document.body.appendChild(script);
		}
	}
}
